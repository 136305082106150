/* eslint-disable @next/next/no-img-element */
import Image from 'next/image';
export interface IBasicImage {
  url: string;
  title: string;
  width?: number;
  height?: number;
}

export interface IBasicProps {
  data: IBasicImage;
}

const BasicImage = (data: IBasicImage) => {
  const { url, title, width, height } = data;
  
  if(url === undefined && title=== undefined){
   return <span>&nbsp;</span>;
  }

  let imgOut:JSX.Element;
  if(width !== undefined && height !== undefined){
    imgOut = <Image  src={url as string} alt={title as string} width={width} height={height} />;
  } else {
    imgOut = <Image  src={url as string} alt={title as string} fill />;
  }
  
  return imgOut;
};

export default BasicImage;
